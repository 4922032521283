<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('profile.title') }}
      </h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div
          style="width: 100%; height: 80vh"
          class="flex justify-center"
          v-if="!model"
        >
          <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
        </div>
        <div class="intro-y box dark:bg-dark-8 p-5" v-if="model">
          <!-- <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer">
            <div>
              <img alt="Profile Image" class="rounded-full" data-action="zoom"
                :src="model.avatar ? model.avatar : require(`@/assets/images/${$f()[0].photos[0]}`)" />
              <div
                class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-17 rounded-full p-2">
                <CameraIcon class="w-4 h-4 text-white" />
              </div>
            </div>

            <input type="file" id="profileImage" name="upload" accept="image/png, image/jpeg, image/jpg"
              style="position: absolute; top: 0%; opacity: 0 !important"
              class="cursor-pointer w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer"
              @input="emitEvent($event)" />
          </div> -->
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full"
                v-model="model.firstName"
                :placeholder="i18n('iam.fields.firstName')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full"
                v-model="model.lastName"
                :placeholder="i18n('iam.fields.lastName')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                disabled
                id="crud-form-1"
                type="text"
                class="form-control w-full"
                v-model="model.email"
                :placeholder="i18n('profile.form.emailAddress')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12" style="dir: ltr">
              <vue-tel-input
                v-model="model.phoneNumber"
                mode="international"
                :inputOptions="isRTL ? optionsAr : options"
                :dropdownOptions="telDropOptions"
              ></vue-tel-input>
            </div>
          </div>
          <div class="mt-5 flex justify-center">
            <router-link to="/change-password" class="btn btn-primary">
              {{ i18n('profile.changePassword') }}
            </router-link>
          </div>

          <!-- END -->
          <div class="mt-5" :class="isRTL ? 'text-left' : 'text-right'">
            <button
              type="button"
              class="btn btn-primary w-24"
              @click="doSubmit()"
            >
              {{ i18n('common.save') }}
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import { FileUploader } from '@/shared/uploader/file-uploader'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'
// import Message from '@/shared/message/toastify'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.email
  // fields.avatar
])

export default {
  // setup() {
  //   const image = ref(null)
  //   const imageUrl = ref('')

  //   return {
  //     image,
  //     imageUrl
  //   }
  // },
  data() {
    return {
      uploadLoading: false,
      model: null,
      rules: formSchema.rules(),
      errorMessage: null,
      options: { placeholder: 'Enter Your Phone Number', showDialCode: true },
      optionsAr: { placeholder: 'ادخل رقم تليفونك', showDialCode: true },
      telDropOptions: {
        showFlags: true
      }
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile'
    }),
    fields() {
      return fields
    }
  },
  async mounted() {
    // this.model = {
    //   fullName: this.currentUser.fullName || null,
    //   phoneNumber: this.currentUser.phoneNumber || null,
    //   email: this.currentUser.email || null,
    //   avatar: this.currentUser.avatar || null
    // }
    console.log(this.currentUser)
    this.model = formSchema.initialValues(this.currentUser || {})
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.profile')
    }
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.model) {
        const value = this.model[key]
        if (value) {
          this.errorMessage = 'profile.form.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
    },
    // emitEvent(event) {
    //   var reader = new FileReader()
    //   reader.readAsDataURL(event.target.files[0])
    //   reader.onload = (e) => {
    //     const image = {
    //       name: event.target.files[0].name,
    //       size: event.target.files[0].size,
    //       lastModifiedDate: event.target.files[0].lastModifiedDate,
    //       base64: reader.result
    //     }
    //     this.image = image
    //     const file = event.target.files[0]
    //     this.imageUrl = URL.createObjectURL(file)
    //     this.model.avatar = this.imageUrl
    //   }
    // },
    // doReset() {
    // this.model = formSchema.initialValues(this.currentUser)
    // },
    async doSubmit() {
      try {
        // if (!this.isFormValid()) {
        //   const ERROR = this.i18n(this.errorMessage)
        //   return Message.error(ERROR)
        // }
        // if (this.image) {
        //   this.uploadLoading = true
        //   const profileImage = document.getElementById('profileImage').files[0]
        //   const path = `user/avatars/profile/${this.currentUser.id}`
        //   const { publicUrl } = await FileUploader.upload(
        //     path,
        //     profileImage,
        //     'avatar'
        //   )
        //   this.model.avatar = publicUrl
        // }

        const { email, ...values } = formSchema.cast(this.model)
        await this.doUpdateProfile(values)
        // this.uploadLoading = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
